import { SVGProps } from "react";

export const SvgIconLeftArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_4803_993)">
        <path
          d="M5.74886 10.8891C5.90965 10.7284 5.99997 10.5104 5.99997 10.2831C5.99997 10.0558 5.90964 9.83774 5.74886 9.67708L2.92631 6.85452H11.1428C11.4491 6.85452 11.7321 6.69113 11.8851 6.42595C12.0383 6.16077 12.0383 5.83399 11.8851 5.56881C11.7321 5.30363 11.4491 5.14024 11.1428 5.14024H2.92631L5.74886 2.31768C5.96315 2.10072 6.04598 1.78626 5.96642 1.49188C5.88687 1.19757 5.65691 0.967621 5.3626 0.888062C5.06823 0.808576 4.75376 0.89141 4.5368 1.10562L0.251107 5.39132C0.0903269 5.55203 0 5.77007 0 5.99735C0 6.22469 0.0903338 6.44272 0.251107 6.60338L4.5368 10.8891C4.69752 11.0499 4.91555 11.1402 5.14283 11.1402C5.37018 11.1402 5.58815 11.0498 5.74886 10.8891V10.8891Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_4803_993">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
