import { SVGProps } from "react";

export const SvgIconSetting = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_3836_5232)">
        <path
          d="M5.48176 0C5.6075 0 5.73431 0.00428188 5.86204 0.0129166C6.8061 0.0966245 7.71323 0.419691 8.49787 0.951479C9.28236 1.48334 9.91835 2.20633 10.3458 3.0524C10.9633 4.19698 11.1249 5.53294 10.798 6.79167L15.1255 11.1192C15.6455 11.6185 15.9566 12.2967 15.9958 13.0166C16.035 13.7365 15.7993 14.4444 15.3366 14.9973C14.8339 15.5572 14.1232 15.8857 13.3711 15.9059C12.619 15.9261 11.8917 15.6362 11.3597 15.1043L6.99908 10.7439C5.67059 11.1296 4.24523 10.998 3.00989 10.3756C1.77453 9.75299 0.820735 8.68569 0.340447 7.38842C-0.139823 6.09111 -0.111067 4.65996 0.421088 3.38297C0.491237 3.21398 0.609915 3.06962 0.762204 2.96821C0.914562 2.86681 1.09355 2.81286 1.27651 2.81336C1.51835 2.81293 1.75029 2.90941 1.92041 3.08132L3.60893 4.76984C3.83994 4.99928 4.17563 5.08841 4.49004 5.00377C4.80439 4.91914 5.05001 4.67358 5.13465 4.35916C5.21929 4.0448 5.13016 3.70911 4.90072 3.4781L3.2658 1.84319C3.04579 1.62468 2.95138 1.30976 3.01483 1.00626C3.07834 0.702829 3.29107 0.452052 3.58016 0.340083C4.18873 0.114499 4.8327 -0.000668162 5.48176 4.43229e-05L5.48176 0ZM12.4901 12.3592C12.276 12.5734 12.1557 12.8638 12.1557 13.1666C12.1557 13.4695 12.276 13.7599 12.4901 13.974C12.7042 14.1881 12.9947 14.3084 13.2975 14.3084C13.6003 14.3084 13.8908 14.1881 14.1048 13.974C14.319 13.7599 14.4393 13.4695 14.4393 13.1666C14.4393 12.8638 14.319 12.5734 14.1048 12.3592C13.8907 12.1452 13.6003 12.0248 13.2975 12.0248C12.9947 12.0248 12.7042 12.1451 12.4901 12.3592Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_3836_5232">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
