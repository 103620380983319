import { SVGProps } from "react";

export const SvgIconCompany = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_3836_5215)">
        <path
          d="M4.2096 7.73688C4.2096 8.20194 3.83256 8.57899 3.3675 8.57899C2.90243 8.57899 2.52539 8.20194 2.52539 7.73688C2.52539 7.27182 2.90243 6.89478 3.3675 6.89478C3.83256 6.89478 4.2096 7.27182 4.2096 7.73688Z"
          fill="#414552"
        />
        <path
          d="M4.2096 11.1054C4.2096 11.5704 3.83256 11.9475 3.3675 11.9475C2.90243 11.9475 2.52539 11.5704 2.52539 11.1054C2.52539 10.6403 2.90243 10.2632 3.3675 10.2632C3.83256 10.2632 4.2096 10.6403 4.2096 11.1054Z"
          fill="#414552"
        />
        <path
          d="M10.1061 4.36847C10.1061 4.83353 9.72904 5.21058 9.26398 5.21058C8.79892 5.21058 8.42188 4.83353 8.42188 4.36847C8.42188 3.90341 8.79892 3.52637 9.26398 3.52637C9.72904 3.52637 10.1061 3.90341 10.1061 4.36847Z"
          fill="#414552"
        />
        <path
          d="M10.1061 7.73688C10.1061 8.20194 9.72904 8.57899 9.26398 8.57899C8.79892 8.57899 8.42188 8.20194 8.42188 7.73688C8.42188 7.27182 8.79892 6.89478 9.26398 6.89478C9.72904 6.89478 10.1061 7.27182 10.1061 7.73688Z"
          fill="#414552"
        />
        <path
          d="M10.1061 11.1054C10.1061 11.5704 9.72904 11.9475 9.26398 11.9475C8.79892 11.9475 8.42188 11.5704 8.42188 11.1054C8.42188 10.6403 8.79892 10.2632 9.26398 10.2632C9.72904 10.2632 10.1061 10.6403 10.1061 11.1054Z"
          fill="#414552"
        />
        <path
          d="M13.4733 4.36847C13.4733 4.83353 13.0962 5.21058 12.6312 5.21058C12.1661 5.21058 11.7891 4.83353 11.7891 4.36847C11.7891 3.90341 12.1661 3.52637 12.6312 3.52637C13.0962 3.52637 13.4733 3.90341 13.4733 4.36847Z"
          fill="#414552"
        />
        <path
          d="M13.4733 7.73688C13.4733 8.20194 13.0962 8.57899 12.6312 8.57899C12.1661 8.57899 11.7891 8.20194 11.7891 7.73688C11.7891 7.27182 12.1661 6.89478 12.6312 6.89478C13.0962 6.89478 13.4733 7.27182 13.4733 7.73688Z"
          fill="#414552"
        />
        <path
          d="M13.4738 1.00006H8.42119C7.75119 1.00006 7.10855 1.26625 6.63475 1.74C6.16094 2.21375 5.8948 2.85638 5.8948 3.52644V4.36855H2.52638C1.85639 4.36855 1.21374 4.63474 0.739941 5.10849C0.266139 5.58224 0 6.22487 0 6.89493V13.6318C0 13.8551 0.0887496 14.0693 0.246653 14.2272C0.404556 14.3851 0.618762 14.4739 0.842105 14.4739H15.1579C15.3813 14.4739 15.5955 14.3851 15.7533 14.2272C15.9112 14.0693 16 13.8551 16 13.6318V3.52651C16 2.85652 15.7338 2.21387 15.2601 1.74007C14.7863 1.26627 14.1437 1.00013 13.4736 1.00013L13.4738 1.00006ZM5.89487 13.6316H0.84224V6.8948C0.842766 6.44828 1.02033 6.02019 1.33612 5.70447C1.65184 5.38868 2.07993 5.21111 2.52645 5.21059H5.89487V13.6316ZM15.158 13.6316H13.4738V11.1053C13.4738 10.8045 13.3133 10.5264 13.0528 10.376C12.7922 10.2256 12.4712 10.2256 12.2107 10.376C11.9501 10.5264 11.7896 10.8045 11.7896 11.1053V13.6316H6.73698V3.52638C6.7375 3.07986 6.91507 2.65177 7.23085 2.33604C7.54658 2.02025 7.97467 1.84269 8.42119 1.84217H13.4738C13.9203 1.84269 14.3484 2.02025 14.6642 2.33604C14.9799 2.65177 15.1575 3.07986 15.158 3.52638V13.6316Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_3836_5215">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
