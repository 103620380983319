import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ReservationFrameApi extends BaseApi {
  async getReservationFrameList(
    parameters: APIPaths["/operator-dash/reservation-frame/list"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/operator-dash/reservation-frame/list"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/operator-dash/reservation-frame/list", { params: parameters })).data;
  }
  async getReservationFrameById(
    parameters: APIPaths["/operator-dash/reservation-frame/{id}"]["get"]["parameters"]["path"],
  ): Promise<
    APIPaths["/operator-dash/reservation-frame/{id}"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/operator-dash/reservation-frame/${parameters.id}`)).data;
  }
  async postReservationFrameNew(
    requestBody: APIPaths["/operator-dash/reservation-frame/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/operator-dash/reservation-frame/new"]["post"]["responses"]["201"]["content"]["application/json"]
  > {
    return (await this.post("operator-dash/reservation-frame/new", requestBody)).data;
  }
  async patchReservationFrameById(
    parameters: APIPaths["/operator-dash/reservation-frame/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/operator-dash/reservation-frame/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/operator-dash/reservation-frame/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.patch(`/operator-dash/reservation-frame/${parameters.id}`, requestBody)).data;
  }
  async deleteReservationFrameById(
    parameters: APIPaths["/operator-dash/reservation-frame/{id}"]["parameters"]["path"],
  ): Promise<
    APIPaths["/operator-dash/reservation-frame/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete(`/operator-dash/reservation-frame/${parameters.id}`)).data;
  }
}
