import { SVGProps } from "react";

export const SvgIconCaretDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" {...props}>
      <g clipPath="url(#clip0_4803_624)">
        <path
          d="M3.99942 6.2863C3.84774 6.28648 3.70223 6.22617 3.59508 6.11877L0.163822 2.68751C0.0215623 2.54262 -0.033212 2.33317 0.019961 2.13717C0.0730827 1.94121 0.226191 1.78809 0.42215 1.73498C0.618155 1.68181 0.8276 1.73659 0.97249 1.87884L3.99943 4.90578L7.02638 1.87884C7.17113 1.73587 7.38094 1.6806 7.57734 1.73369C7.7737 1.78676 7.92713 1.94019 7.98021 2.13655C8.03324 2.33296 7.97797 2.54277 7.83505 2.68752L4.40379 6.11878C4.29666 6.22618 4.15114 6.2865 3.99946 6.28632L3.99942 6.2863Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_4803_624">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
