import * as React from "react";

import { IconButton } from "@mui/material";

import { appColor } from "@/styles/app-colors";

import { SvgIconCancel } from "@/svgs";
import { SnackbarKey, useSnackbar } from "notistack";

type Props = {
  snackbarKey: SnackbarKey;
};
const SnackbarCloseButton: React.FC<Props> = (props) => {
  const { closeSnackbar } = useSnackbar();
  const onClickSnackbar = () => closeSnackbar(props.snackbarKey);
  return (
    <IconButton onClick={onClickSnackbar} color={"inherit"} size={"small"}>
      <SvgIconCancel width={10} height={10} fill={appColor.App.white} />
    </IconButton>
  );
};

export default SnackbarCloseButton;
