import { SVGProps } from "react";

export const SvgIconDeleteButton = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13.0001 16.5V12.4999C13.0001 12.3673 13.0527 12.2402 13.1465 12.1464C13.2403 12.0526 13.3675 11.9999 13.5001 11.9999C13.6327 11.9999 13.7598 12.0526 13.8536 12.1464C13.9474 12.2402 14.0001 12.3673 14.0001 12.4999V16.5C14.0001 16.6326 13.9474 16.7598 13.8536 16.8536C13.7598 16.9473 13.6327 17 13.5001 17C13.3675 17 13.2403 16.9473 13.1465 16.8536C13.0527 16.7598 13.0001 16.6326 13.0001 16.5ZM10.5001 17C10.6327 17 10.7599 16.9473 10.8537 16.8536C10.9474 16.7598 11.0001 16.6326 11.0001 16.5V12.4999C11.0001 12.3673 10.9474 12.2402 10.8537 12.1464C10.7599 12.0526 10.6327 11.9999 10.5001 11.9999C10.3675 11.9999 10.2403 12.0526 10.1466 12.1464C10.0528 12.2402 10.0001 12.3673 10.0001 12.4999V16.5C10.0001 16.5657 10.0131 16.6307 10.0382 16.6913C10.0633 16.752 10.1001 16.8071 10.1466 16.8536C10.193 16.9 10.2481 16.9368 10.3088 16.9619C10.3694 16.9871 10.4345 17 10.5001 17ZM18.5001 5.00006H16L15.2764 3.55279C15.1933 3.38669 15.0657 3.247 14.9077 3.14936C14.7497 3.05173 14.5677 3.00001 14.382 3H9.618C9.43229 3 9.25025 3.05172 9.09227 3.14935C8.9343 3.24699 8.80663 3.38668 8.72358 3.55279L8 5L5.5 5.00006C5.36739 5.00006 5.24021 5.05274 5.14645 5.14651C5.05268 5.24027 5 5.36745 5 5.50006C5 5.63267 5.05268 5.75985 5.14645 5.85361C5.24021 5.94738 5.36739 6.00006 5.5 6.00006H18.5001C18.6327 6.00006 18.7598 5.94738 18.8536 5.85361C18.9474 5.75985 19.0001 5.63267 19.0001 5.50006C19.0001 5.36745 18.9474 5.24027 18.8536 5.14651C18.7598 5.05274 18.6327 5.00006 18.5001 5.00006ZM6 8H18V18C18 18.7956 17.6839 19.5587 17.1213 20.1213C16.5587 20.6839 15.7956 21 15 21H9C8.20435 21 7.44129 20.6839 6.87868 20.1213C6.31607 19.5587 6 18.7956 6 18V8ZM7 18C7.00061 18.5302 7.21152 19.0386 7.58646 19.4135C7.9614 19.7885 8.46975 19.9994 9 20H15C15.5302 19.9994 16.0386 19.7885 16.4135 19.4135C16.7885 19.0386 16.9994 18.5302 17 18V9H7V18Z"
        fill="#EE2D38"
      />
    </svg>
  );
};
