import { useEffect } from "react";

import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { authTokenInfoAtom, selectedSpotIdAtom } from "@/global-states/common-atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import localforage from "localforage";

type Props = {
  children: React.ReactNode;
};

const SpotProvider: React.FC<Props> = (props) => {
  const { operatorSpotApi } = useApiConnector();
  const setSelectedSpotId = useSetAtom(selectedSpotIdAtom);
  const authTokenInfo = useAtomValue(authTokenInfoAtom);
  const { data: spots } = useQuery({
    queryKey: ["operatorSpotApi.getSpotList", {}],
    queryFn: () => operatorSpotApi.getSpotList({}),
    enabled: !!authTokenInfo?.accessToken,
  });

  useEffect(() => {
    if (!spots) return;
    (async () => {
      const spotId = await localforage.getItem<string>(LocalForageKeys.spot.selectedSpotId);
      setSelectedSpotId(spotId && spots.objects.find((spot) => spot.id === spotId) ? spotId : spots.objects[0].id);
    })();
  }, [spots]);

  return <>{props.children}</>;
};

export default SpotProvider;
