import { EmotionCache, Global } from "@emotion/react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { ReactNode, useEffect, useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { _appStyled } from "@/styles/_app";
import { kintoSans } from "@/styles/app-typography";
import { muiTheme } from "@/styles/mui-theme";
import { ENV } from "@/utils/env";

import SnackbarCloseButton from "@/components/common/SnackBarCloseButton";
import AuthProvider from "@/components/provider/AuthProvider";
import SpotProvider from "@/components/provider/SpotProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";

dayjs.extend(isBetween);

const APP_ENV = process.env.APP_ENV;

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      keepPreviousData: true,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps } = props;
  const [devToolsNode, setDevToolsNode] = useState<ReactNode>(null);

  useEffect(() => {
    if (APP_ENV !== ENV.LOCAL) {
      return;
    }
    setTimeout(async () => {
      const { ReactQueryDevtools } = await import("@tanstack/react-query-devtools");
      const node = <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />;
      setDevToolsNode(node);
    }, 1000);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {devToolsNode}
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Global styles={_appStyled} />
        <JotaiProvider>
          <AuthProvider>
            <SpotProvider>
              <SnackbarProvider
                autoHideDuration={5000}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                maxSnack={3}
                preventDuplicate
                action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
              >
                <main className={kintoSans.className}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </main>
              </SnackbarProvider>
            </SpotProvider>
          </AuthProvider>
        </JotaiProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
