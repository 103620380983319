import { css, keyframes } from "@emotion/react";
import { SVGProps } from "react";

export const SVGIconLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
    css={loadingStyled}
  >
    <g clipPath="url(#clip0_454_1036)">
      <path
        fill="#656D7E"
        d="M4.865 12.973H.973a.973.973 0 010-1.946h3.892a.973.973 0 110 1.946zm2.09 3.099a.967.967 0 00-.688.285L3.514 19.11a.973.973 0 101.376 1.375l2.753-2.752a.973.973 0 00-.688-1.661zm10.09-8.144a.966.966 0 00.688-.285l2.753-2.753a.973.973 0 10-1.376-1.376l-2.753 2.753a.973.973 0 00.688 1.661zM4.891 3.515A.973.973 0 003.515 4.89l2.752 2.753a.973.973 0 101.376-1.376L4.891 3.515zm18.136 7.512h-3.892a.973.973 0 100 1.946h3.892a.973.973 0 000-1.946zm-5.294 5.33a.973.973 0 10-1.376 1.376l2.753 2.752a.973.973 0 101.376-1.376l-2.753-2.752zM12 0a.974.974 0 00-.973.973v3.892a.973.973 0 001.946 0V.973A.974.974 0 0012 0zm0 18.162a.974.974 0 00-.973.973v3.892a.973.973 0 101.946 0v-3.892a.974.974 0 00-.973-.973z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_454_1036">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingStyled = css`
  animation: ${rotate} 2s linear infinite;
`;
