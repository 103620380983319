import { SVGProps } from "react";

import { appColor } from "@/styles/app-colors";

export const SvgIconDeleteDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
    <g clipPath="url(#clip0_5514_461)">
      <path
        fill={appColor.Background.grayLight}
        d="M2.007 10a2 2 0 002 2h4a2 2 0 002-2V3.333h-8V10zm4.666-3.5a.5.5 0 011 0v2.333a.5.5 0 01-1 0V6.5zm-2.333 0a.5.5 0 011 0v2.333a.5.5 0 01-1 0V6.5zm6.333-4.833A.333.333 0 0110.34 2H1.673a.333.333 0 010-.667H3.34L3.822.37A.667.667 0 014.42 0h3.176a.667.667 0 01.596.369l.482.964h1.667a.333.333 0 01.333.334z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_5514_461">
        <path fill={appColor.Background.white} d="M0 0H12V12H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
