export const appColor = {
  // その他。共通
  App: {
    primary: "#464BA8",
    accent: "#DE3E44",
    gray: "#BDBDBD",
    white: "#FFFFFF",
  },
  Text: {
    primary: "#414552", // 主にメインテキストなどに使用
    secondary: "#727171", // 非アクティブ要素や注釈のテキストなどに使用
    white: "#FFFFFF",
    black: "#000000",
    gray: "#687385",
    ghost: "#c6cacd",
    brand: "#464BA8",
    periodic: "#EF8D4A",
    purpleGray: "#858e9f",
    error: "#b20030",
    red: "#EE2D38",
    darkGray: "#656D7E",
    lightRed: "#DB3737",
    mossGreen: "#006908",
    lightBlack: "#353a44",
    lightGray: "#687385",
  },
  Border: {
    primary: "#414552", // Divider/Border/Strokeが主にアクティブなときに使用
    secondary: "#F7F7F7", // Divider/Border/Strokeが主に非アクティブなときに使用
    gray: "#000000",
    grayLight: "#DDDDDD",
    grayUltraLight: "#EBEEF1",
    third: "#F1F1F1",
    headerBell: "#D3DBE2",
    purpleGray: "#dcb0ff",
    darkGray: "#A9A9A9",
    brand: "#464BA8",
    periodic: "#EF8D4A",
    red: "#EE2D38",
    grayLightSecond: "#e4e7ec",
    grayLightWhite: "#F6F8FA",
    black: "#181B1E",
    blueLight: "#3F51B5",
    lightGreen: "#A6EB84",
    lightGray: "#ebeef1",
    dataGridGray: "#596171",
  },
  Background: {
    primary: "#FBFBFB", // メインバックグラウンドで使用
    secondary: "#F7F7F7", // サブコンテンツのバックグラウンドで使用
    error: "#FFE5E7",
    selected: "#D0D0D0", // リストアイテムなどが選択状態のときに使用
    tertiary: "#F6F7F8", // セレクト状態などで使用月白(げっぱく)
    auth: "#FBFBFB", // Authの背景色
    lightRed: "#DB3737", // ボタンの背景色
    white: "#FFFFFF",
    grayLight: "#DDDDDD",
    brand: "#464BA8",
    brandGray: "#D8DDE6",
    brandActive: "#6469C6",
    blur: "#000221",
    purpleGray: "#fcf9ff",
    pink: "#FFE5E7",
    brownLight: "#F0EFE9",
    black: "#181B1E",
    lightGray: "#F3F4F5",
    bluishGray: "#F6F8FA",
    darkGray: "#414552",
    darkLightGray: "#87909F",
    lightBluish: "#F3F6FA",
    lightGreen: "#4EDD76",
    ultraLightGreen: "#D7F7C2",
    dataGridGray: "#596171",

    // アクセント
    blue: "#DEEDFF",
    green: "#CBE5D3",
    yellow: "#F0E4C3",
    red: "#F2CBCB",
    gray: "#DADBDD",
  },
  Form: {
    primary: "#FFFFFF", // 活性状態のときに使用
    secondary: "#F3F4F5", // 非活性状態のときに使用
    error: "#EE2D38",
  },
  Button: {
    primary: {
      color: "#181B1E",
      text: "#FFFFFF",
    }, // メインボタン
    secondary: {
      color: "#FFFFFF",
      text: "#181B1E",
      hover: "#f8f8f8",
    },
    icon: {
      color: "#F0F3F6",
    },
    negative: {
      color: "#D6D9DC",
      text: "#D6D9DC",
    }, //主にボタンが非アクティブ状態で使用
    accent: {
      red: "#FF7663",
      gray: "#7F7F89",
      facebook: "#1877F2",
    },
    social: {
      facebook: "#5E6E9F",
    },
  },
} as const;
