import { SVGProps } from "react";

export const SvgIconClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g clipPath="url(#clip0_3836_244)">
        <path
          d="M7.42624 5.99972L11.7047 1.72128C11.9594 1.46649 12.0589 1.09518 11.9656 0.747186C11.8724 0.399192 11.6005 0.127315 11.2525 0.0341143C10.9046 -0.0590882 10.5332 0.0404173 10.2784 0.29512L6.00002 4.57355L1.72159 0.29512C1.46679 0.0404072 1.09549 -0.0590862 0.747491 0.0341143C0.399496 0.127317 0.127619 0.399212 0.0344188 0.747186C-0.0588624 1.09518 0.0406431 1.46649 0.295364 1.72128L4.57379 5.99972L0.295364 10.2781C0.0406514 10.5329 -0.0588421 10.9043 0.0343585 11.2523C0.12764 11.6003 0.399456 11.8721 0.74743 11.9654C1.09551 12.0586 1.46681 11.9591 1.72161 11.7044L6.00004 7.42596L10.2785 11.7044C10.5333 11.9591 10.9046 12.0586 11.2526 11.9654C11.6006 11.8721 11.8724 11.6003 11.9657 11.2523C12.0589 10.9043 11.9594 10.5329 11.7047 10.2781L7.42624 5.99972Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_3836_244">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
