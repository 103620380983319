import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class AchievementApi extends BaseApi {
  async getAchievementListAll(
    parameters: APIPaths["/operator-dash/achievement/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/operator-dash/achievement/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/operator-dash/achievement/list-all", { params: parameters })).data;
  }
}
