import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class TicketApi extends BaseApi {
  async getTicketById(
    parameters: APIPaths["/operator-dash/ticket/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/operator-dash/ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/operator-dash/ticket/${parameters.id}`)).data;
  }
  async getTicketList(
    parameters: APIPaths["/operator-dash/ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/operator-dash/ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/operator-dash/ticket/list", {
        params: parameters,
      })
    ).data;
  }
  async postTicketNew(
    requestBody: APIPaths["/operator-dash/ticket/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/operator-dash/ticket/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/operator-dash/ticket/new", requestBody)).data;
  }
  async patchTicketById(
    parameters: APIPaths["/operator-dash/ticket/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/operator-dash/ticket/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/operator-dash/ticket/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/operator-dash/ticket/${parameters.id}`, requestBody)).data;
  }
  async deleteTicketById(
    parameters: APIPaths["/operator-dash/ticket/{id}"]["delete"]["parameters"]["path"],
  ): Promise<APIPaths["/operator-dash/ticket/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/operator-dash/ticket/${parameters.id}`)).data;
  }
}
