import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { useAuth } from "@/custom-hooks/apis-common/use-auth";

import { authPath, routePath } from "@/constants/app-paths";
import { LocalForageKeys } from "@/constants/local-forage-keys";
import { authTokenInfoAtom, initializingAtom } from "@/global-states/common-atoms";
import { useAtom } from "jotai";
import localforage from "localforage";

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = (props) => {
  const router = useRouter();
  const { authSync } = useAuth();

  const [authTokenInfo] = useAtom(authTokenInfoAtom);
  const [initializing, setInitializing] = useAtom(initializingAtom);

  useEffect(() => {
    if (!initializing) {
      return;
    }

    (async () => {
      if (await authSync()) {
        if (!router.asPath || router.asPath === routePath || router.asPath.startsWith(authPath)) {
          await router.push(routePath);
        }
      } else if (router.asPath.match(/\[.*\]/)) {
        return;
      } else if (!router.asPath.startsWith(authPath)) {
        await localforage.setItem(LocalForageKeys.auth.urlRedirectToAfterLogin, router.asPath);
        await router.push(authPath);
      }

      setTimeout(() => setInitializing(false), 1);
    })();
  }, [initializing, router.asPath]);

  useEffect(() => {
    const timeout = setTimeout(() => authSync({ silent: true }), 1000 * 60 * 15);
    return () => clearTimeout(timeout);
  }, [authTokenInfo?.accessToken]);

  return <>{props.children}</>;
};

export default AuthProvider;
