import { useRouter } from "next/router";
import { useCallback } from "react";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { authTokenInfoAtom, loadingAtom } from "@/global-states/common-atoms";
import Axios from "axios";
import { useSetAtom } from "jotai";
import localforage from "localforage";

export const useApiConfig = () => {
  const router = useRouter();

  const setLoading = useSetAtom(loadingAtom);
  const setAuthTokenInfo = useSetAtom(authTokenInfoAtom);

  const resetAuth = useCallback(async () => {
    setAuthTokenInfo(undefined);
    setLoading(false);
    await localforage.removeItem(LocalForageKeys.auth.AccessToken);
    await localforage.removeItem(LocalForageKeys.auth.RefreshToken);
  }, [router.asPath]);

  const throwApiError = useCallback((err: any) => {
    if (Axios.isAxiosError(err)) {
      // console.error(err.response?.data?.devMessage);
      throw new Error(err.response?.data?.message);
    }
    console.error("不明なエラー", err);
    throw err;
  }, []);

  const getRefreshToken = useCallback(async (): Promise<string> => {
    const refreshToken = await localforage.getItem<string>(LocalForageKeys.auth.RefreshToken);
    if (typeof refreshToken === "string") {
      return refreshToken;
    } else {
      await resetAuth();
      return "";
    }
  }, []);

  return {
    resetAuth: resetAuth,
    throwApiError: throwApiError,
    getRefreshToken: getRefreshToken,
  };
};
