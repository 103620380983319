import { SVGProps } from "react";

export const SvgIconEditButton = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.5176 4.41896C19.2711 4.17125 18.978 3.97488 18.6551 3.8412C18.3323 3.70752 17.9861 3.63918 17.6367 3.64015H17.6299C17.2793 3.64009 16.9322 3.70978 16.6088 3.84516C16.2853 3.98054 15.9921 4.17891 15.7461 4.42872L4.14453 16.1484C4.05178 16.2419 3.99982 16.3683 4 16.5V19.5C3.99996 19.5656 4.01287 19.6307 4.03798 19.6913C4.0631 19.752 4.09992 19.8072 4.14636 19.8536C4.1928 19.9 4.24793 19.9369 4.30861 19.962C4.36929 19.9871 4.43433 20 4.5 20H7.5C7.56566 20 7.63068 19.987 7.69134 19.9619C7.752 19.9368 7.80711 19.8999 7.85352 19.8535L19.522 8.18502C20.0205 7.68485 20.3001 7.00724 20.2992 6.30107C20.2984 5.5949 20.0173 4.91795 19.5176 4.41896ZM7.293 19H5V16.7055L14.9488 6.65578L17.293 8.99996L7.293 19ZM18.815 7.47796L18 8.29296L15.6523 5.94521L16.457 5.13228C16.6104 4.9763 16.7934 4.85244 16.9952 4.76793C17.1969 4.68343 17.4135 4.63997 17.6323 4.6401C17.8537 4.62727 18.075 4.66471 18.2798 4.74961C18.4847 4.83451 18.6676 4.96467 18.8149 5.13033C19.1257 5.44196 19.3002 5.86407 19.3002 6.30414C19.3002 6.74421 19.1257 7.16633 18.815 7.47796Z"
        fill="#414552"
      />
    </svg>
  );
};
