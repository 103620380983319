import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ReservationApi extends BaseApi {
  async getReservationList(
    parameters: APIPaths["/operator-dash/reservation/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/operator-dash/reservation/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/operator-dash/reservation/list", { params: parameters })).data;
  }
  async getReservationById(
    parameters: APIPaths["/operator-dash/reservation/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/operator-dash/reservation/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/operator-dash/reservation/${parameters.id}`)).data;
  }
  async patchReservationById(
    parameters: APIPaths["/operator-dash/reservation/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/operator-dash/reservation/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/operator-dash/reservation/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/operator-dash/reservation/${parameters.id}`, requestBody)).data;
  }

  async putReservationCancelById(
    parameters: APIPaths["/operator-dash/reservation/{id}/cancel"]["put"]["parameters"]["path"],
  ): Promise<
    APIPaths["/operator-dash/reservation/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/operator-dash/reservation/${parameters.id}/cancel`)).data;
  }
}
