export const LocalForageKeys = {
  auth: {
    AccessToken: "95817060051575189ffb2d2381595874",
    RefreshToken: "2f6aa362a908868fc24c5fa7ec2e42c8",
    urlRedirectToAfterLogin: "8b5a92b18c833f835e1fbc85d8cef78a",
  },
  spot: {
    selectedSpotId: "19d036056d4cdd8eec7b2e98088435c0",
  },
} as const;
