import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class PaymentApi extends BaseApi {
  async getPaymentList(
    parameters: APIPaths["/operator-dash/payment/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/operator-dash/payment/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/operator-dash/payment/list", { params: parameters })).data;
  }
  async getPaymentById(
    parameters: APIPaths["/operator-dash/payment/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/operator-dash/payment/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/operator-dash/payment/${parameters.id}`)).data;
  }
}
