import { SVGProps } from "react";

import { appColor } from "@/styles/app-colors";

export const SvgIconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M6.20929 10.0725C6.0753 9.93862 6.00003 9.75692 6.00003 9.56752C6.00003 9.37806 6.07531 9.19637 6.20929 9.06249L8.56142 6.71035L1.7143 6.71035C1.45912 6.71035 1.22329 6.57419 1.09572 6.35321C0.968094 6.13223 0.968094 5.85991 1.09572 5.63893C1.22328 5.41794 1.45912 5.28179 1.7143 5.28179L8.56142 5.28179L6.20929 2.92965C6.0316 2.74868 5.96319 2.48708 6.0296 2.24227C6.09595 1.99751 6.28719 1.80627 6.53194 1.73993C6.77676 1.67352 7.03836 1.74194 7.21933 1.91961L10.7907 5.49103C10.9247 5.62496 11 5.80666 11 5.99606C11 6.18551 10.9247 6.3672 10.7907 6.50108L7.21933 10.0725C7.0854 10.2065 6.9037 10.2818 6.7143 10.2818C6.52484 10.2818 6.3432 10.2065 6.20927 10.0725L6.20929 10.0725Z"
      fill={props.fill ?? appColor.Text.primary}
    />
  </svg>
);
