import { SVGProps } from "react";

import { appColor } from "@/styles/app-colors";

export const SvgIconArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M10.0726 5.79071C9.9387 5.9247 9.757 5.99997 9.5676 5.99997C9.37815 5.99997 9.19646 5.92469 9.06258 5.79071L6.71044 3.43858V10.2857C6.71044 10.5409 6.57428 10.7767 6.3533 10.9043C6.13231 11.0319 5.86 11.0319 5.63902 10.9043C5.41803 10.7767 5.28187 10.5409 5.28187 10.2857V3.43858L2.92974 5.79071C2.74877 5.9684 2.48717 6.03681 2.24236 5.9704C1.9976 5.90405 1.80636 5.71281 1.74001 5.46806C1.67361 5.22324 1.74202 4.96164 1.9197 4.78067L5.49112 1.20926C5.62505 1.07527 5.80674 1 5.99614 1C6.1856 1 6.36729 1.07528 6.50117 1.20926L10.0726 4.78067C10.2066 4.9146 10.2818 5.0963 10.2818 5.2857C10.2818 5.47516 10.2066 5.6568 10.0726 5.79073L10.0726 5.79071Z"
      fill={props.fill ?? appColor.Text.primary}
    />
  </svg>
);
