import { APICompsSchema } from "@/apis/api-config";

import { atom } from "jotai";

export const loadingAtom = atom<boolean>(false);

export const initializingAtom = atom<boolean>(true);

export const authTokenInfoAtom = atom<APICompsSchema["AuthTokenResponse"] | undefined>(undefined);

export const selectedSpotIdAtom = atom<string>("");
