import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class CheckinApi extends BaseApi {
  async getCheckinList(
    parameters: APIPaths["/operator-dash/checkin/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/operator-dash/checkin/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/operator-dash/checkin/list", { params: parameters })).data;
  }
  async getCheckinById(
    parameters: APIPaths["/operator-dash/checkin/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/operator-dash/checkin/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/operator-dash/checkin/${parameters.id}`)).data;
  }
  async patchCheckinById(
    parameters: APIPaths["/operator-dash/checkin/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/operator-dash/checkin/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/operator-dash/checkin/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/operator-dash/checkin/${parameters.id}`, requestBody)).data;
  }
}
