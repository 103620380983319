import { SVGProps } from "react";

export const SvgIconCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill={props.fill ?? "#222"}
      d="M9.902 8l5.704-5.705A1.345 1.345 0 0013.705.394L8 6.098 2.295.394A1.345 1.345 0 00.394 2.295L6.098 8 .394 13.704a1.345 1.345 0 001.901 1.902L8 9.902l5.705 5.704a1.345 1.345 0 001.901-1.902L9.902 8z"
    ></path>
  </svg>
);
