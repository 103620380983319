import { useMemo } from "react";

import { AchievementApi } from "@/apis/connect/achievement-api";
import { AuthApi } from "@/apis/connect/auth-api";
import { CheckinApi } from "@/apis/connect/checkin-api";
import { EventApi } from "@/apis/connect/event-api";
import { ImageApi } from "@/apis/connect/image-api";
import { OperatorApi } from "@/apis/connect/operator-api";
import { PaymentApi } from "@/apis/connect/payment-api";
import { ReservationApi } from "@/apis/connect/reservation-api";
import { ReservationFrameApi } from "@/apis/connect/reservation-frame-api";
import { SpotApi } from "@/apis/connect/spot-api";
import { TicketApi } from "@/apis/connect/ticket-api";

import { authTokenInfoAtom } from "@/global-states/common-atoms";
import { useAtom } from "jotai";

export const useApiConnector = () => {
  const [authTokenInfo] = useAtom(authTokenInfoAtom);
  const operatorApi = useMemo(() => new OperatorApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorAuthApi = useMemo(() => new AuthApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorEventApi = useMemo(() => new EventApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorSpotApi = useMemo(() => new SpotApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorReservationApi = useMemo(() => new ReservationApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorTicketApi = useMemo(() => new TicketApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorImageApi = useMemo(() => new ImageApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorCheckinApi = useMemo(() => new CheckinApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorPaymentApi = useMemo(() => new PaymentApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const operatorReservationFrameApi = useMemo(
    () => new ReservationFrameApi(authTokenInfo?.accessToken),
    [authTokenInfo],
  );
  const operatorAchievementApi = useMemo(() => new AchievementApi(authTokenInfo?.accessToken), [authTokenInfo]);

  return {
    operatorApi,
    operatorAuthApi,
    operatorEventApi,
    operatorSpotApi,
    operatorReservationApi,
    operatorTicketApi,
    operatorImageApi,
    operatorCheckinApi,
    operatorPaymentApi,
    operatorReservationFrameApi,
    operatorAchievementApi,
  };
};
