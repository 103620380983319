import { useRouter } from "next/router";
import { useCallback } from "react";

import { APIPaths } from "@/apis/api-config";
import { AuthApi } from "@/apis/connect/auth-api";
import { useApiConfig } from "@/custom-hooks/apis-common/use-api-config";
import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { authTokenInfoAtom, loadingAtom } from "@/global-states/common-atoms";
import { useAtom, useSetAtom } from "jotai";
import localforage from "localforage";

export const useAuth = () => {
  const router = useRouter();
  const { operatorAuthApi } = useApiConnector();
  const { resetAuth, throwApiError, getRefreshToken } = useApiConfig();

  const setLoading = useSetAtom(loadingAtom);
  const [authTokenInfo, setAuthTokenInfo] = useAtom(authTokenInfoAtom);

  const authSync = useCallback(
    async (opts?: { silent?: boolean }) => {
      if (!opts?.silent) {
        setLoading(true);
      }
      try {
        const refreshToken = await getRefreshToken();
        if (!refreshToken) {
          await resetAuth();
          return false;
        }
        const refreshAuthApi = new AuthApi(refreshToken);
        const resultRefresh = await refreshAuthApi.postRenewToken();
        setAuthTokenInfo(resultRefresh);
        return true;
      } catch (err) {
        await resetAuth();
        throwApiError(err);
      } finally {
        if (!opts?.silent) {
          setLoading(false);
        }
      }
    },
    [authTokenInfo?.accessToken, router.asPath],
  );

  const authLogin = useCallback(
    async (
      requestBody: APIPaths["/operator-dash/auth/login"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      setLoading(true);
      try {
        const resultLogin = await operatorAuthApi.postLogin(requestBody);
        setAuthTokenInfo(resultLogin);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultLogin.accessToken);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultLogin.refreshToken);
        return true;
      } catch (err) {
        await resetAuth();
        throwApiError(err);
      } finally {
        setLoading(false);
      }
    },
    [operatorAuthApi],
  );

  const authLogout = useCallback(async () => {
    setLoading(true);
    try {
      await resetAuth();
      return true;
    } catch (err) {
      throwApiError(err);
    } finally {
      setLoading(false);
    }
  }, [operatorAuthApi]);

  const authRefresh = useCallback(
    async (refreshToken: string) => {
      setLoading(true);
      try {
        const refreshAuthApi = new AuthApi(refreshToken);
        const resultRefresh = await refreshAuthApi.postRenewToken();
        setAuthTokenInfo(resultRefresh);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultRefresh.accessToken);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultRefresh.refreshToken);
        return true;
      } catch (err) {
        await resetAuth();
        throwApiError(err);
      } finally {
        setLoading(false);
      }
    },
    [operatorAuthApi],
  );

  return {
    authSync: authSync,
    authLogin: authLogin,
    authLogout: authLogout,
    authRefresh,
  };
};
