import { SVGProps } from "react";

import { appColor } from "@/styles/app-colors";

export const SvgIconArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M1.92746 6.20929C2.06138 6.0753 2.24308 6.00003 2.43248 6.00003C2.62194 6.00003 2.80363 6.07531 2.93751 6.20929L5.28965 8.56142L5.28965 1.7143C5.28965 1.45912 5.42581 1.22329 5.64679 1.09572C5.86777 0.968094 6.14009 0.968094 6.36107 1.09572C6.58206 1.22328 6.71821 1.45912 6.71821 1.7143L6.71821 8.56142L9.07035 6.20929C9.25132 6.0316 9.51292 5.96319 9.75773 6.0296C10.0025 6.09595 10.1937 6.28719 10.2601 6.53194C10.3265 6.77676 10.2581 7.03836 10.0804 7.21933L6.50897 10.7907C6.37504 10.9247 6.19334 11 6.00394 11C5.81449 11 5.6328 10.9247 5.49892 10.7907L1.9275 7.21933C1.79352 7.0854 1.71824 6.9037 1.71824 6.7143C1.71824 6.52484 1.79352 6.3432 1.9275 6.20927L1.92746 6.20929Z"
      fill={props.fill ?? appColor.Text.primary}
    />
  </svg>
);
