import { SVGProps } from "react";

export const SVGIconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_4803_2260)">
      <path
        d="M15.7789 14.7024L12.733 11.6566C12.7028 11.6328 12.6708 11.6114 12.6371 11.5928C13.785 10.1772 14.3457 8.37514 14.2036 6.55839C14.0615 4.7415 13.2276 3.04855 11.8737 1.82858C10.5199 0.608659 8.74949 -0.045022 6.92767 0.00241212C5.10594 0.049765 3.37194 0.794659 2.08334 2.08334C0.79472 3.37196 0.0499273 5.10604 0.002412 6.92767C-0.0450201 8.74947 0.608638 10.5199 1.82858 11.8737C3.04843 13.2276 4.74144 14.0617 6.55839 14.2036C8.3752 14.3457 10.1773 13.785 11.5928 12.6371C11.6114 12.6708 11.6328 12.7028 11.6566 12.733L14.7024 15.7789C14.8949 15.9701 15.1748 16.0444 15.4367 15.9738C15.6987 15.9033 15.9034 15.6985 15.974 15.4366C16.0446 15.1746 15.9703 14.8947 15.7791 14.7022L15.7789 14.7024ZM7.11831 13.2102C5.50267 13.2102 3.95326 12.5684 2.81086 11.426C1.66842 10.2835 1.02666 8.73405 1.02666 7.11853C1.02666 5.50301 1.66842 3.95349 2.81086 2.81109C3.9533 1.66865 5.50279 1.02689 7.11831 1.02689C8.73383 1.02689 10.2834 1.66865 11.4258 2.81109C12.5682 3.95353 13.21 5.50301 13.21 7.11853C13.2081 8.73363 12.5657 10.282 11.4237 11.4239C10.2817 12.566 8.73338 13.2084 7.11827 13.2102L7.11831 13.2102Z"
        fill="#687385"
      />
    </g>
    <defs>
      <clipPath id="clip0_4803_2260">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
