import { SVGProps } from "react";

import { appColor } from "@/styles/app-colors";

export const SvgIconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
    <g clipPath="url(#clip0_5514_464)">
      <path
        fill={appColor.Background.dataGridGray}
        d="M7.427 1.779l1.189-1.2A1.939 1.939 0 019.998 0h.005a1.954 1.954 0 011.382 3.334l-1.201 1.201L7.427 1.78zm-.774.782L.106 9.175A.365.365 0 000 9.433v2.2A.366.366 0 00.367 12h2.2a.367.367 0 00.26-.107l6.579-6.58-2.753-2.752z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_5514_464">
        <path fill={appColor.Background.white} d="M0 0H12V12H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
