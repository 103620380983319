import { SVGProps } from "react";

export const SVGIconLogoBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="183" height="49" fill="none" viewBox="0 0 183 49" {...props}>
    <g clipPath="url(#clip0_1421_5038)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.654 23.238C16.509 10.261 27.306 0 40.499 0c13.194 0 23.99 10.261 24.846 23.238H15.654z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M182.857 24.38H0v.763h182.857v-.762zM64.4 28.19H16.232v.762h48.17v-.762zM63.482 32H17.15v.762h46.333V32zM61.858 35.81H18.773v.761h43.085v-.761zM59.347 39.62H21.281v.761h38.066v-.762zM55.535 43.429h-30.44v.762h30.44v-.762zM48.819 47.238H31.814V48H48.82v-.762z"
      ></path>
      <path
        fill="#000"
        d="M49.838 15.588a.552.552 0 01-.55-.55A4.343 4.343 0 0044.95 10.7a.552.552 0 01-.55-.55c0-.302.247-.55.55-.55a5.444 5.444 0 015.438 5.438c0 .302-.248.55-.55.55z"
      ></path>
      <path
        fill="#000"
        d="M49.832 15.588a.552.552 0 01-.55-.55A5.444 5.444 0 0154.719 9.6c.303 0 .55.248.55.55 0 .303-.247.55-.55.55a4.343 4.343 0 00-4.337 4.338c0 .302-.248.55-.55.55zM58.452 19.994a.412.412 0 01-.413-.413c0-1.12-.91-2.032-2.033-2.032a.412.412 0 110-.825 2.86 2.86 0 012.858 2.857.412.412 0 01-.412.413z"
      ></path>
      <path
        fill="#000"
        d="M58.451 19.994a.412.412 0 01-.412-.413 2.86 2.86 0 012.857-2.857.412.412 0 110 .825c-1.12 0-2.032.91-2.032 2.032a.412.412 0 01-.413.413z"
      ></path>
    </g>
    <path
      fill="#fff"
      d="M100.695 48.123c-4.488 0-5.895-3.06-5.895-6.65 0-3.591 1.224-6.651 5.997-6.651 4.162 0 5.794 2.183 5.794 4.59h-1.612c-.102-1.755-1.162-3.264-4.182-3.264-3.55 0-4.386 2.366-4.386 5.324 0 2.958 1 5.325 4.386 5.325 3.081 0 4.284-1.877 4.284-4.59h-4.773V40.9h6.324v7.1h-.939l-.346-2.653c-.694 1.653-2 2.775-4.652 2.775zM115.276 48.123c-3.957 0-4.977-2.591-4.977-5.345 0-2.734 1.04-5.345 4.998-5.345 3.957 0 4.998 2.611 4.998 5.345 0 2.774-1.041 5.345-5.019 5.345zm-3.427-5.345c0 2.203.673 4.08 3.427 4.08 2.775 0 3.468-1.836 3.468-4.08 0-2.183-.673-4.04-3.447-4.04-2.754 0-3.448 1.837-3.448 4.04zM122.764 48l5.365-13.056h2.101L135.494 48h-1.653l-1.101-2.774h-7.161L124.457 48h-1.693zm3.264-3.998h6.263l-3.121-7.854-3.142 7.854zM139.946 48V36.25h-4.08v-1.306h9.751v1.306h-4.08V48h-1.591zM145.869 48l5.366-13.056h2.101L158.599 48h-1.652l-1.102-2.774h-7.16L147.563 48h-1.694zm3.264-3.998h6.263l-3.121-7.854-3.142 7.854zM161.827 48V34.944h2.386l5.1 11.343 5.039-11.343h2.428V48h-1.612V36.903L170.089 48h-1.571l-5.1-11.097V48h-1.591zM181.358 48V34.944h1.591V48h-1.591z"
    ></path>
    <defs>
      <clipPath id="clip0_1421_5038">
        <path fill="#fff" d="M0 0H182.857V48H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
