import { SVGProps } from "react";

export const SvgIconThreeDots = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="1.5" cy="6" r="1.5" fill="#596171" />
      <circle cx="6" cy="6" r="1.5" fill="#596171" />
      <circle cx="10.5" cy="6" r="1.5" fill="#596171" />
    </svg>
  );
};
