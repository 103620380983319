import { SVGProps } from "react";

export const SvgIconExpandMore = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g clipPath="url(#clip0_3836_5444)">
        <path
          d="M5.99913 9.43007C5.77161 9.43034 5.55334 9.33986 5.39262 9.17876L0.245734 4.03187C0.0323435 3.81454 -0.0498181 3.50037 0.0299416 3.20636C0.109624 2.91242 0.339287 2.68275 0.633226 2.60308C0.927233 2.52333 1.2414 2.60549 1.45874 2.81887L5.99915 7.35928L10.5396 2.81887C10.7567 2.60442 11.0714 2.52152 11.366 2.60114C11.6606 2.68075 11.8907 2.9109 11.9703 3.20544C12.0499 3.50004 11.967 3.81476 11.7526 4.03189L6.60569 9.17878C6.44499 9.33989 6.22671 9.43036 5.99918 9.43008L5.99913 9.43007Z"
          fill="#414552"
        />
      </g>
      <defs>
        <clipPath id="clip0_3836_5444">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
