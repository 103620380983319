export const routePath = "/";

// Auth login
export const authPath = "/auth";

// Achievements
export const achievementsPath = "/achievements";
export const achievementsHourlyPath = `${achievementsPath}/hourly`;

// Reset Password
export const resetPasswordPath = `${authPath}/reset-password`;
export const resetPasswordSentMailPath = `${resetPasswordPath}/sent`;
export const editPasswordPath = (id: string) => `${resetPasswordPath}/${id}`;
export const editPasswordCompletePath = `${resetPasswordPath}/complete`;

// Change Password
export const changePasswordPath = `/change-password`;
export const changePasswordCompletePath = `${changePasswordPath}/complete`;

// Event Create
export const eventRoot = "/event";
export const eventListPath = `${eventRoot}/list`;
export const eventCreatePath = `${eventRoot}/create`;
export const eventCreateConfirmPath = `${eventCreatePath}/confirm`;
export const eventCreateCompletePath = `${eventCreatePath}/complete`;
export const eventEditPath = (id: string) => `${eventRoot}/edit/${id}`;
export const eventEditConfirmPath = (id: string) => `${eventEditPath(id)}/confirm`;
export const eventEditCompletePath = (id: string) => `${eventEditPath(id)}/complete`;

// reservation
export const reservationRoot = "/reservation";
export const reservationListPath = `${reservationRoot}/list`;
export const reservationDetailPath = (id: string) => `${reservationRoot}/detail/${id}`;

// Plan Create
export const planRoot = "/plan";
export const planListPath = `${planRoot}/list`;
export const planCreatePath = `${planRoot}/create`;
export const planCreateConfirmPath = `${planCreatePath}/confirm`;
export const planCreateCompletePath = `${planCreatePath}/complete`;
export const planEditPath = (id: string) => `${planRoot}/edit/${id}`;
export const planEditConfirmPath = (id: string) => `${planEditPath(id)}/confirm`;
export const planEditCompletePath = (id: string) => `${planEditPath(id)}/complete`;
export const planReservationPath = (id: string) => `${planRoot}/detail/${id}`;
export const planEditReservationPath = (id: string) => `${planRoot}/reservation-frame/edit/${id}`;
export const planCreateDetailPath = `${planRoot}/reservation-frame/create`;

// Change Mail Address
export const changeMailAddressRoot = "/change-mail-address";
export const changeMailAddressVerificationPath = `${changeMailAddressRoot}/verification`;
export const changeMailAddressCompletePath = (id: string, hashKey: string) =>
  `${changeMailAddressRoot}/complete/${id}?key=${hashKey}`;
