import { SVGProps } from "react";

export const SvgIconError = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="24" rx="12" fill="#FFE5E7" />
      <path
        d="M11.5913 14.62L11.3533 11.54V8.04H12.6973V11.54L12.4593 14.62H11.5913ZM10.9333 16.202C10.9333 15.474 11.3533 15.292 12.0113 15.292C12.6693 15.292 13.1033 15.474 13.1033 16.202C13.1033 16.93 12.6693 17.098 12.0113 17.098C11.3533 17.098 10.9333 16.93 10.9333 16.202Z"
        fill="#EE2D38"
      />
      <rect x="4" y="4" width="16" height="16" rx="8" stroke="#EE2D38" />
    </svg>
  );
};
