import { css } from "@emotion/react";
import { SVGProps } from "react";

export const SVGIconSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    css={successIconStyled}
  >
    <path
      d="M10.0001 17.9999C9.73489 17.9998 9.48058 17.8944 9.29308 17.7069L5.29308 13.7069C5.10837 13.5188 5.00541 13.2654 5.0066 13.0017C5.0078 12.7381 5.11305 12.4857 5.29945 12.2992C5.48586 12.1128 5.73834 12.0076 6.00195 12.0064C6.26557 12.0052 6.51899 12.1082 6.70708 12.2929L9.96196 15.5477L18.2569 6.33095C18.4343 6.13385 18.6828 6.0153 18.9476 6.00138C19.2124 5.98746 19.4719 6.07932 19.669 6.25673C19.8661 6.43415 19.9847 6.68261 19.9986 6.94743C20.0125 7.21226 19.9207 7.47177 19.7432 7.66887L10.7432 17.6689C10.6524 17.7697 10.542 17.851 10.4188 17.9079C10.2956 17.9648 10.1621 17.9961 10.0264 17.9999H10.0001Z"
      fill="white"
    />
  </svg>
);

const successIconStyled = css`
  border-radius: 999999995904px;
  background: #4edd76;
`;
