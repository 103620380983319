import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class OperatorApi extends BaseApi {
  async getOperatorMe(): Promise<
    APIPaths["/operator-dash/operator/me"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/operator-dash/operator/me")).data;
  }
  async patchOperatorMe(
    requestBody: APIPaths["/operator-dash/operator/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/operator-dash/operator/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/operator-dash/operator/me`, requestBody)).data;
  }
  async putOperatorMePassword(
    requestBody: APIPaths["/operator-dash/operator/me/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/operator-dash/operator/me/password"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/operator-dash/operator/me/put`, requestBody)).data;
  }
}
